import {PrintOutlined, RequestQuoteOutlined} from '@mui/icons-material';
import {Box, Checkbox, Grid, Typography} from '@mui/material';
import {Field, Formik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import ReactToPrint from 'react-to-print';
import * as Yup from 'yup';
import DragNDrop from '../../../components/drag-n-drop';
import ImpressaoOrcamento from '../../../components/impressao/impressao-orcamento';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import {RoundTooltipButton} from '../../../components/mui-button';
import Textarea from '../../../components/textarea/mui-textarea';
import {faturarPor, formasPagamento, orcamentoSituacao} from '../../../constants/enum-labels';
import {SelectCidades} from '../../../containers/selects/mui-cidades';
import Data from '../../../utils/data';
import Number from '../../../utils/number';

const pageStyle = `
  @page {
    size: A4 landscape;
    margin: 5mm 10mm 5mm 10mm;
  }
  @media print {
    html, body {
      height: 210mm;
      width: 297mm;
      overflow: initial !important;
    }
  }
  @media all {
    .page-footer {
      display: block !important;
    }
  }
`;

const EdicaoOrcamento = ({orcamento = {}, proposta}) => {
  const componentRef = useRef();

  const [readOnly] = useState(true);
  const [anunciante] = useState(orcamento.anunciante);
  const [formData, setFormData] = useState({
    ...orcamento,
    situacao: {
      value: orcamento.situacao,
      label: orcamentoSituacao[orcamento.situacao],
    },
    repasseAgencia: Number.regraDeTresPorcentagem(orcamento.comissaoAgencia, Number.stringToFloat(orcamento.valorTotalVeiculacaoMensal), true),
  });
  const [orcamentoItens, setOrcamentoItens] = useState(orcamento.itens || []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!orcamento?.id) return;

    setFormData({
      ...orcamento,
      situacao: {
        value: orcamento.situacao,
        label: orcamentoSituacao[orcamento.situacao],
      },
      repasseAgencia: Number.regraDeTresPorcentagem(orcamento.comissaoAgencia, Number.stringToFloat(orcamento.valorTotalVeiculacaoMensal), true),
    });
    setOrcamentoItens(orcamento.itens || []);
  }, [orcamento]);

  const handleChangeFieldValor = (field, percentField, valor, valorReferencia) => {
    setFormData({
      ...formData,
      [field]: valor,
      [percentField]: Number.currencyFormat(Number.regraDeTresPorcentagem(valor, valorReferencia)),
    });
  };

  const handleChangeFieldPercent = (field, percentField, valor, valorReferencia) => {
    const valorCalculo = Number.regraDeTresValor(valorReferencia, valor);
    setFormData({
      ...formData,
      [percentField]: valor,
      [field]: Number.currencyFormat(valorCalculo),
    });

    return valorCalculo;
  };

  const calcFields = () => {
    const totalVeiculos = orcamentoItens.map((item) => parseInt(item.quantidadeVeiculos || 0)).reduce((total, item) => total + item, 0);
    const valorTotalVeiculacaoMensal = orcamentoItens.map((item) => Number.stringToFloat(item.valorTotalCidade || 0)).reduce((total, item) => total + item, 0);
    const valorTotalVeiculacao = valorTotalVeiculacaoMensal * parseInt(formData.quantidadeMeses || 0);
    const valorTotalProducao = orcamentoItens.map((item) => Number.stringToFloat(item.producaoPorVeiculo || 0) * item.quantidadeVeiculos).reduce((total, item) => total + item, 0);
    const valorTotalCampanhaBruto = valorTotalVeiculacao + (valorTotalProducao + valorTotalProducao * 0.1 * formData.quantidadeMeses);
    const valorTotalCampanha = valorTotalCampanhaBruto - orcamento.valorReajuste;
    let comissaoAgencia = Number.currencyFormat(formData.comissaoAgencia);
    if (!readOnly) {
      comissaoAgencia = Number.currencyFormat(Number.regraDeTresValor(valorTotalVeiculacaoMensal, formData.repasseAgencia || 0));
    }

    setFormData({
      ...formData,
      totalVeiculos,
      valorTotalVeiculacaoMensal,
      valorTotalVeiculacao,
      valorTotalProducao,
      valorTotalCampanha,
      comissaoAgencia,
      valorTotalCampanhaBruto,
    });
  };

  useEffect(() => {
    calcFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orcamentoItens, formData.quantidadeMeses, formData.producaoPorVeiculo]);

  const renderParcelasVeiculacao = () => {
    const valorTotalLiquido = formData.valorTotalVeiculacaoMensal * formData.quantidadeMeses - Number.stringToFloat(formData.comissaoAgencia);
    const veiculacao = Array.from(Array(Number.safeParseInt(formData.parcelasVeiculacao)).keys());
    const parcelaVeiculacao =
      formData.faturarPor === 'VALOR_LIQUIDO' ? valorTotalLiquido / formData.parcelasVeiculacao : (formData.valorTotalVeiculacaoMensal * formData.quantidadeMeses) / formData.parcelasVeiculacao;

    return veiculacao.map((_, index) => (
      <Grid container key={index}>
        <Typography sx={styles.fontParcela}>{`${index + 1}ª. ${Number.currencyFormat(parcelaVeiculacao)} - ${Data.validaFimDeSemanaData(formData.dataVencimentoVeiculacao, index)}`}</Typography>
        <Box ml="auto">
          <Typography sx={styles.fontParcela}>{`${Data.validaFimDeSemanaData(formData.dataEmissaoNotaVeiculacao, index)}`}</Typography>
        </Box>
      </Grid>
    ));
  };

  const renderParcelasProducao = () => {
    const producao = Array.from(Array(Math.max(Number.safeParseInt(formData.parcelasProducao), formData.quantidadeMeses)).keys());
    const valorTotalProducao = Number.stringToFloat(formData?.valorTotalProducao);
    const reservaTecnica = valorTotalProducao * 0.1;
    const parcelaProducao = valorTotalProducao / formData.parcelasProducao;

    return producao.map((_, index) => {
      const valor = index < formData.parcelasProducao ? parcelaProducao : 0;
      return (
        <Grid container key={index}>
          <Typography sx={styles.fontParcela}>{`${index + 1}ª. ${Number.currencyFormat(valor + reservaTecnica)} - ${Data.validaFimDeSemanaData(formData.dataVencimentoProducao, index)}`}</Typography>
          <Box ml="auto">
            <Typography sx={styles.fontParcela}>{`${Data.validaFimDeSemanaData(formData.dataEmissaoNotaProducao, index)}`}</Typography>
          </Box>
        </Grid>
      );
    });
  };

  const hideCampoReajuste = () => {
    return !Number.currencyToFloat(orcamento.valorReajuste) || proposta.situacao !== 'APROVADO';
  };

  return (
    <Grid sx={styles.container}>
      <Grid className="styled-scroll" sx={styles.form}>
        <ReactToPrint
          pageStyle={pageStyle}
          documentTitle={`KM Money - ${orcamento?.anunciante?.nomeFantasia || 'Anunciante'}`}
          onBeforePrint={() => setLoading(false)}
          onBeforeGetContent={() => setLoading(true)}
          trigger={() => (
            <RoundTooltipButton sx={{top: '0px !important', right: '28px'}} size="small" id="icon-toolbar" title={'Imprimir'} icon={<PrintOutlined />} loading={loading} loadingColor="grey" />
          )}
          content={() => componentRef.current}
        />
        <Grid sx={styles.content} container>
          <Formik
            validationSchema={OrcamentoScheme}
            enableReinitialize
            initialValues={{
              ...formData,
              anunciante,
            }}>
            <Box component="form" pb={'24px'}>
              <Grid container spacing={2} style={{width: '100%'}}>
                <Grid item xs={12} md={4}>
                  <InputV2 label="Cliente" name="cliente" value={proposta.anunciante.nomeFantasia} disabled />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputV2 label="Titulo" name="titulo" value={proposta.titulo || ''} disabled />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputV2 label="Períodos" name="quantidadeMeses" value={orcamento.quantidadeMeses || 0} disabled />
                </Grid>
                <Grid item xs={2}>
                  <InputV2 label="Duração período" name="diasPeriodo" value={orcamento.diasPeriodo} disabled />
                </Grid>
              </Grid>
              <Grid container item xs={12} justifyContent="space-between" sx={{marginTop: '8px'}}>
                <Typography sx={styles.headerText}>Cidades</Typography>
              </Grid>
              <Grid item xs={12} sx={styles.cidadesContainer}>
                {orcamentoItens.map((item, index) => (
                  <OrcamentoItem
                    key={item.key || item.id}
                    item={item}
                    quantidadeMeses={orcamento.quantidadeMeses}
                    setValue={(e) => {
                      orcamentoItens[index] = e;
                      setOrcamentoItens([...orcamentoItens]);
                    }}
                    readOnly={readOnly}
                  />
                ))}
              </Grid>
              <Grid container item xs={12} justifyContent="flex-end">
                <Grid container item xs={12} md={10}>
                  <Grid container item direction="row" spacing={2} justifyContent="flex-end">
                    <Grid item xs={12} md={4}>
                      <InputV2 label="Total de carros" name="totalVeiculos" value={formData.totalVeiculos || 0} disabled />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputV2 label="Veiculação / período" name="valorTotalVeiculacaoMensal" value={Number.currencyFormat(formData.valorTotalVeiculacaoMensal || 0)} mask="moeda" disabled />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputV2 label="Total veiculação" name="valorTotalVeiculacao" value={Number.currencyFormat(formData.valorTotalVeiculacao || 0)} disabled />
                    </Grid>
                    <Grid container item xs={12} md={4} spacing={0}>
                      <Grid item xs={5}>
                        <InputV2
                          label="Agência"
                          name="repasseAgencia"
                          mask="porcento"
                          value={formData.repasseAgencia || 0}
                          onChange={({target}) => handleChangeFieldPercent('comissaoAgencia', 'repasseAgencia', target.value, formData.valorTotalVeiculacaoMensal)}
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <InputV2
                          label="Valor / período"
                          name="comissaoAgencia"
                          mask="moeda"
                          value={formData.comissaoAgencia}
                          onChange={({target}) => handleChangeFieldValor('comissaoAgencia', 'repasseAgencia', target.value, formData.valorTotalVeiculacaoMensal)}
                          disabled={readOnly}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={4} spacing={0}>
                      <Grid item xs={7}>
                        <InputV2 label="Total produção" name="valorTotalProducao" mask="moeda" value={Number.currencyFormat(formData.valorTotalProducao || 0)} disabled />
                      </Grid>
                      <Grid item xs={5}>
                        <InputV2
                          label="Reserva técnica"
                          name="reservaTecnica"
                          mask="moeda"
                          value={Number.currencyFormat(Number.safeParseFloat(formData.valorTotalProducao) * 0.1 * formData.quantidadeMeses)}
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputV2 label="Valor total da campanha" name="valorTotalCampanha" mask="moeda" value={Number.currencyFormat(formData.valorTotalCampanha || 0)} disabled />
                    </Grid>
                    <Grid item xs={12} md={4.5} style={hideCampoReajuste() ? {display: 'none'} : null}>
                      <InputV2 label="Valor reajuste" name="valorReajuste" mask="moeda" value={Number.currencyFormat(formData.valorReajuste || 0)} disabled />
                    </Grid>
                    <Grid item xs={12} md={4.5} style={hideCampoReajuste() ? {display: 'none'} : null}>
                      <InputV2 label="Valor reajustado da campanha" name="valorTotalCampanha" mask="moeda" value={Number.currencyFormat(formData.valorTotalCampanha || 0)} disabled />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} pt={2}>
                <Textarea
                  name="observacoes"
                  label="Observações"
                  value={formData.observacoes || ''}
                  onChange={({target}) => setFormData({...formData, observacoes: target.value})}
                  disabled={readOnly}
                />
              </Grid>
              {proposta.situacao === 'APROVADO' && orcamento.situacao === 'APROVADO' && (
                <Grid container item xs={12} spacing={2} padding={'16px 0'} mt={2}>
                  <Grid item xs={12} md={6} sx={styles.formaPagamentoBorder}>
                    <Box padding="10px 0px 11px 0px">
                      <Typography sx={styles.labelFaturamento}>Faturamento da veiculação</Typography>
                    </Box>
                    <Box>
                      <Grid container spacing={1}>
                        <Grid item xs={5}>
                          <InputV2 label="Formas de Pagamento" value={formasPagamento[formData.formaPagamentoVeiculacao]} disabled />
                        </Grid>
                        <Grid item xs={3}>
                          <InputV2
                            name="parcelas"
                            label="Parcelas"
                            value={formData.parcelasVeiculacao}
                            onChange={({target}) =>
                              setFormData({
                                ...formData,
                                parcelasVeiculacao: target.value,
                                refazParcelamento: true,
                              })
                            }
                            disabled
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputV2 label="Faturar por" value={faturarPor[formData.faturarPor]} disabled />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Box sx={styles.boxParcelamento}>
                        <Typography sx={styles.parcelamentoTitulo}>Parcelamento</Typography>
                        <Typography sx={styles.parcelamentoTitulo}>Emissão NF</Typography>
                      </Box>
                      {renderParcelasVeiculacao()}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} sx={styles.formaPagamentoBorder}>
                    <Box sx={styles.box}>
                      <Typography sx={styles.labelFaturamento}>Faturamento da produção</Typography>
                      <Checkbox
                        checked={formData.faturarProducao || false}
                        onChange={() =>
                          setFormData((f) => ({
                            ...f,
                            faturarProducao: !f.faturarProducao,
                            refazParcelamento: true,
                          }))
                        }
                        disabled
                      />
                    </Box>
                    {formData.faturarProducao && (
                      <Box>
                        <Box>
                          <Grid container spacing={1}>
                            <Grid item xs={5}>
                              <InputV2 label="Formas de Pagamento" value={formasPagamento[formData.formaPagamentoProducao]} disabled />
                            </Grid>
                            <Grid item xs={3}>
                              <InputV2
                                name="parcelasProducao"
                                label="Parcelas"
                                value={formData.parcelasProducao}
                                onChange={({target}) =>
                                  setFormData({
                                    ...formData,
                                    parcelasProducao: target.value,
                                    refazParcelamento: true,
                                  })
                                }
                                disabled
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box sx={styles.boxParcelamento}>
                          <Typography sx={styles.parcelamentoTitulo}>Parcelamento</Typography>
                          <Typography sx={styles.parcelamentoTitulo}>Emissão NF</Typography>
                        </Box>
                        {renderParcelasProducao()}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography sx={styles.pedidoInsercaoHeader} style={{marginRight: '12px'}}>
                            P.I. (Veiculação)
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{minHeight: '120px'}}>
                          {formData.piVeiculacao?.id && <DragNDrop id={formData.piVeiculacao?.id} fileName={formData.piVeiculacao?.nome} disabled />}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography sx={styles.pedidoInsercaoHeader}>A.P. (Produção)</Typography>
                        </Grid>
                        <Grid item xs={12} style={{minHeight: '120px'}}>
                          {formData.piProducao?.id && <DragNDrop id={formData.piProducao?.id} fileName={formData.piProducao?.nome} disabled />}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Formik>
        </Grid>
        <div style={{overflow: 'hidden', height: '0px'}}>
          <Grid ref={componentRef} style={{background: '#fff'}}>
            <ImpressaoOrcamento orcamento={formData} orcamentoItens={orcamentoItens} />
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

const OrcamentoScheme = Yup.object().shape({
  anunciante: Yup.object().shape({value: Yup.string()}).nullable().required('Campo obrigatório'),
  diasPeriodo: Yup.string().required('Campo obrigatório'),
  quantidadeMeses: Yup.string().required('Campo obrigatório'),
});

const OrcamentoItem = ({setValue, item = {}, quantidadeMeses, readOnly}) => {
  const [openTabela, setOpenTabela] = useState(false);
  const [cidade, setCidade] = useState(item.cidade);
  const [orcamentoItem, setOrcamentoItem] = useState({
    ...item,
    valorFinalVeiculo: Number.currencyFormat((item.modeloAnuncio?.valorVenda || 0) - Number.stringToFloat(item.valorDescontoPorVeiculo)),
    valorDescontoPorVeiculo: Number.currencyFormat(item.valorDescontoPorVeiculo),
    valorMensalVeiculo: Number.currencyFormat(item.modeloAnuncio?.valorVenda || 0),
    valorTotalCidade: Number.currencyFormat(item.valorTotalCidade),
    desconto: Number.regraDeTresPorcentagem(item.valorDescontoPorVeiculo, item.modeloAnuncio?.valorVenda, true),
    modeloAnuncio: {
      ...item.modeloAnuncio,
      valorVenda: item.valorMensalPorVeiculo,
      valorInstaladorPorInstalacao: Number.currencyFormat(item.producaoPorVeiculo),
    },
  });

  const handleChangeFieldValor = (field, percentField, valor, valorReferencia) => {
    const valorFinalVeiculo = (orcamentoItem.modeloAnuncio?.valorVenda || 0) - Number.stringToFloat(valor);
    setState({
      ...orcamentoItem,
      [field]: valor,
      [percentField]: Number.currencyFormat(Number.regraDeTresPorcentagem(valor, valorReferencia)),
      valorFinalVeiculo: Number.currencyFormat(valorFinalVeiculo),
      valorTotalCidade: Number.currencyFormat(valorFinalVeiculo * parseInt(orcamentoItem.quantidadeVeiculos || 0)),
    });
  };

  const handleChangeFieldPercent = (field, percentField, valor, valorReferencia) => {
    const valorDesconto = Number.regraDeTresValor(valorReferencia, valor);
    const valorFinalVeiculo = (orcamentoItem.modeloAnuncio?.valorVenda || 0) - valorDesconto;
    setState({
      ...orcamentoItem,
      [percentField]: valor,
      [field]: Number.currencyFormat(valorDesconto),
      valorFinalVeiculo: Number.currencyFormat(valorFinalVeiculo),
      valorTotalCidade: Number.currencyFormat(valorFinalVeiculo * parseInt(orcamentoItem.quantidadeVeiculos || 0)),
    });

    return valorDesconto;
  };

  useEffect(() => {
    if (!orcamentoItem.modeloAnuncio) return;

    calcValues();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orcamentoItem.modeloAnuncio, orcamentoItem.quantidadeVeiculos, cidade]);

  useEffect(() => {
    if (!cidade?.id || readOnly) return;

    setState({
      ...orcamentoItem,
      mediaKmRodado: cidade?.kmMedio || cidade?.kmPadrao || 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cidade]);

  const calcValues = () => {
    const valorDescontoPorVeiculo = orcamentoItem.valorDescontoPorVeiculo;
    const valorComDesconto = (orcamentoItem.modeloAnuncio?.valorVenda || 0) - Number.stringToFloat(valorDescontoPorVeiculo);

    setState({
      ...orcamentoItem,
      cidade,
      valorDescontoPorVeiculo,
      valorMensalVeiculo: Number.currencyFormat(orcamentoItem.modeloAnuncio?.valorVenda),
      valorFinalVeiculo: Number.currencyFormat(valorComDesconto),
      valorTotalCidade: Number.currencyFormat(valorComDesconto * parseInt(orcamentoItem.quantidadeVeiculos || 0)),
    });
  };

  const setState = (item) => {
    setOrcamentoItem(item);
    setValue(item);
  };

  const {kmEstimado, impactosExternosEstimados, impactosInternosEstimados, totalImpactos} = Number.calcularMetricas(item, quantidadeMeses);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...orcamentoItem,
        cidade,
      }}>
      <Grid sx={styles.itemRowContainer}>
        <Grid container item direction="row" rowSpacing={2} columnSpacing={1}>
          <Grid item xs={3}>
            <Field name="cidade" value={orcamentoItem.cidade} component={SelectCidades} onChange={(cidade) => setCidade(cidade)} disabled={readOnly} />
          </Grid>
          <Grid item xs={4}>
            <Grid item style={{display: 'flex', width: 'inherit', position: 'relative'}}>
              <InputV2
                title={orcamentoItem.modeloAnuncio?.label}
                label="Modelo Anúncio"
                name="modeloAnuncio"
                value={orcamentoItem.modeloAnuncio?.label}
                endAdornment={
                  <RoundTooltipButton
                    type="button"
                    title={'Tabela de preços'}
                    sx={styles.novoClienteButton}
                    onClick={() => setOpenTabela((open) => !open && orcamentoItem?.modeloAnuncio?.id)}
                    icon={<RequestQuoteOutlined />}
                  />
                }
                disabled
              />
              {openTabela && (
                <Grid sx={styles.tabelaModal}>
                  <Grid>
                    <Typography sx={styles.tabelaModalTitle}>Produção</Typography>
                    <Typography sx={styles.tabelaModalDesc} style={{color: '#0000'}}>
                      {' '}
                      -{' '}
                    </Typography>
                    <Typography sx={styles.tabelaModalValue}>{`${Number.currencyFormat(orcamentoItem?.modeloAnuncio.valorInstaladorPorInstalacao)}`}</Typography>
                  </Grid>
                  <Grid sx={styles.tabelaModalContainer} />
                  <Grid>
                    <Typography sx={styles.tabelaModalTitle}>Veiculação</Typography>
                    <Typography sx={styles.tabelaModalDesc} style={{color: '#0000'}}>
                      {' '}
                      -{' '}
                    </Typography>
                    <Typography sx={styles.tabelaModalValue}>{`${Number.currencyFormat(orcamentoItem?.modeloAnuncio.valorVenda)}`}</Typography>
                  </Grid>
                  <Grid sx={styles.tabelaModalContainer} />
                  <Grid>
                    <Typography sx={styles.tabelaModalTitle}>Veiculação</Typography>
                    <Typography sx={styles.tabelaModalDesc}>(Valor recomendado)</Typography>
                    <Typography sx={styles.tabelaModalValue}>
                      {`${Number.format(orcamentoItem?.modeloAnuncio.tabelaTres) || 0} % - ${Number.currencyFormat(
                        ((100 - orcamentoItem?.modeloAnuncio.tabelaTres) / 100) * orcamentoItem?.modeloAnuncio.valorVenda,
                      )}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <InputV2
              label="Qtd. carros"
              name="quantidadeVeiculos"
              value={orcamentoItem.quantidadeVeiculos || ''}
              onChange={({target}) =>
                setOrcamentoItem({
                  ...orcamentoItem,
                  quantidadeVeiculos: target.value,
                })
              }
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={3}>
            <InputV2 label="Valor por veículo / período" name="valorMensalVeiculo" mask="moeda" value={orcamentoItem.valorMensalVeiculo || 0} disabled />
          </Grid>
          <Grid container item xs={4} spacing={0}>
            <Grid item xs={5}>
              <InputV2
                label="Desconto"
                name="desconto"
                mask="porcento"
                value={orcamentoItem.desconto || 0}
                onChange={({target}) => handleChangeFieldPercent('valorDescontoPorVeiculo', 'desconto', target.value, orcamentoItem?.modeloAnuncio?.valorVenda)}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={7}>
              <InputV2
                label="Valor"
                name="valorDescontoPorVeiculo"
                mask="moeda"
                value={orcamentoItem.valorDescontoPorVeiculo || 0}
                onChange={({target}) => handleChangeFieldValor('valorDescontoPorVeiculo', 'desconto', target.value, orcamentoItem?.modeloAnuncio?.valorVenda)}
                disabled={readOnly}
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <InputV2 label="Valor final por veículo" name="valorFinalVeiculo" mask="moeda" value={orcamentoItem.valorFinalVeiculo || 0} disabled />
          </Grid>
          <Grid item xs={2}>
            <InputV2 label="Valor total" name="valorTotalCidade" mask="moeda" value={orcamentoItem.valorTotalCidade || 0} disabled />
          </Grid>
          <Grid item xs={2}>
            <InputV2 label="Produção por carro" name="producaoPorVeiculo" value={Number.currencyFormat(orcamentoItem.producaoPorVeiculo)} disabled />
          </Grid>
          <Grid item xs={2}>
            <InputV2
              label="Produção total"
              name="producaoTotal"
              value={Number.currencyFormat(Number.stringToFloat(orcamentoItem.producaoPorVeiculo || 0) * (orcamentoItem.quantidadeVeiculos || 0))}
              disabled
            />
          </Grid>
          <Grid container item xs={12} justifyContent={'space-around'}>
            <Typography variant="caption">
              <b>Km estimado:</b> {Number.format(Number.safeParseInt(kmEstimado))}
            </Typography>
            <Typography variant="caption">
              <b>Impactos ext. estimados:</b> {Number.format(Number.safeParseInt(impactosExternosEstimados))}
            </Typography>
            <Typography variant="caption">
              <b>Impactos int. estimados:</b> {Number.format(Number.safeParseInt(impactosInternosEstimados))}
            </Typography>
            <Typography variant="caption">
              <b>Total de impactos:</b> {Number.format(Number.safeParseInt(totalImpactos))}
            </Typography>
            <Typography variant="caption">
              <b>CPM:</b> {Number.currencyFormat((Number.currencyToFloat(orcamentoItem.valorTotalCidade) / totalImpactos) * 1000)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Formik>
  );
};

const styles = {
  container: {
    display: 'flex',
    paddingLeft: '0px',
    paddingRight: '4px',
    height: 'inherit',
    flexDirection: 'column',
  },
  form: {
    overflowY: 'auto',
    height: 'inherit',
    padding: '12px',
  },
  headerText: {
    fontSize: '14px',
    fontWeight: 900,
    color: '#00106B',
    padding: '5px 0',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 1,
    paddingBottom: '24px',
  },
  label: {
    display: 'block',
    fontWeight: 'bold',
    color: '#657496',
    fontSize: '14px',
    marginBottom: '3px',
  },
  value: {
    marginLeft: 1,
    display: 'block',
    color: '#657496',
    fontSize: '14px',
    marginBottom: '3px',
  },
  anunciante: {
    display: 'flex',
  },
  buttons: {
    maxWidth: '185px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    background: '#fff',
    width: '100%',
    marginTop: 'auto',
    padding: '15px',

    '@media (min-width: 1024px)': {
      flexDirection: 'row',
    },
  },
  cidadesContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: '#e4e9f3bf',
    padding: '8px',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  actionButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '112px',
    marginLeft: 1,
  },
  itemIcon: {
    width: 18,
  },
  itemButton: {
    width: 35,
    height: 35,
    minWidth: 35,
    margin: '4px 0 4px 12px!important',
  },
  novoClienteButton: {
    borderRadius: '4px !important',
    margin: '0px -14px 0px 0px',
    backgroundColor: '#afb7c9 !important',
    svg: {
      color: '#fff',
    },
  },
  itemRowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: '12px',
    borderRadius: '6px',
    background: '#fff',
    marginTop: '8px',
    border: (theme) => `1px solid ${theme.palette.paterns.blueGraySecundary}`,
    '&:first-child': {
      marginTop: '0px',
    },
  },
  tabelaModal: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    padding: '10px 20px',
    top: 50,
    right: 0,
    width: 'max-content',
    height: 'max-content',
    background: '#E5EAF4',
    zIndex: 5,
    borderRadius: '6px',

    '&::before': {
      right: 10,
      top: -12,
      transform: 'scaleX(2)',
      fontSize: 16,
      height: 5,
      content: "'\\25B2'",
      color: '#E5EAF4',
      position: 'absolute',
    },
  },
  tabelaModalTitle: {
    fontSize: 12,
  },
  tabelaModalDesc: {
    fontSize: 10,
    margin: '-4px 0 4px 0',
  },
  tabelaModalValue: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  tabelaModalContainer: {
    width: '1px',
    height: 'initial',
    margin: '0 20px',
    borderLeft: '1px solid #cecece',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  box: {
    display: 'flex',
    flexDirection: 'row !important',
    alignItems: 'center',
  },
  boxParcelamento: {
    display: 'flex',
    flexDirection: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0px',
  },
  fontParcela: {
    display: 'flex',
    fontSize: '12px',
    color: '#6c7b9b',
    marginRight: '15px',
    alignItems: 'center',
  },
  labelFaturamento: {
    color: (theme) => theme.palette.paterns.navyBlue,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  pedidoInsercaoHeader: {
    fontWeight: '700',
    fontSize: 16,
    lineHeight: '36px',
    color: '#007BE1',
  },
  parcelamentoTitulo: {
    margin: ' 0 10px 5px 0',
    color: '#657496',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  formaPagamentoBorder: {
    borderTop: (theme) => `1px solid ${theme.palette.paterns.blueGraySecundary}`,
  },
};

export default EdicaoOrcamento;
